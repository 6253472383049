<template>
  <div class="main">
    <branchCards addBranch="true" actionPrefix="/edit"></branchCards>
    <Legal></Legal>
  </div>
</template>

<script>
import branchCards from "@/components/layout/branchCards";
import Legal from "@/components/layout/Legal";

export default {
  components: { branchCards, Legal },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Sedes");
  },
  name: "Branch"
};
</script>
